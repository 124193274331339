//@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Ubuntu:400,700&subset=cyrillic-ext');

@font-face {
  font-family: 'robotobold_condensed';
  src: url('../fonts/roboto-boldcondensed-webfont.eot');
  src: url('../fonts/roboto-boldcondensed-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto-boldcondensed-webfont.woff2') format('woff2'),
  url('../fonts/roboto-boldcondensed-webfont.woff') format('woff'),
  url('../fonts/roboto-boldcondensed-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotocondensed';
  src: url('../fonts/roboto-condensed-webfont.eot');
  src: url('../fonts/roboto-condensed-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto-condensed-webfont.woff2') format('woff2'),
  url('../fonts/roboto-condensed-webfont.woff') format('woff'),
  url('../fonts/roboto-condensed-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ubunturegular';
  src: url('../fonts/ubuntu-webfont.eot');
  src: url('../fonts/ubuntu-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu-webfont.woff') format('woff'),
  url('../fonts/ubuntu-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ubuntubold';
  src: url('../fonts/ubuntu-bold-webfont.eot');
  src: url('../fonts/ubuntu-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu-bold-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu-bold-webfont.woff') format('woff'),
  url('../fonts/ubuntu-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

.transition(@time: .2s, @function: ease-out, @element: all) {
  transition: @element @time @function
}

.padding-btn(@value: 40px) {
  padding-left: @value;
  padding-right: @value;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  outline: none;

}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

select {
  appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// Transform icon styles

/*.tcon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  transition: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.tcon > * {
  display: block;
}

.tcon:hover, .tcon:focus {
  outline: none;
}

.tcon::-moz-focus-inner {
  border: 0;
}

.tcon-menu__lines {
  display: inline-block;
  height: 5.71429px;
  width: 40px;
  border-radius: 2.85714px;
  transition: 0.3s;
  background: black;
  position: relative;
}

.tcon-menu__lines::before, .tcon-menu__lines::after {
  display: inline-block;
  height: 5.71429px;
  width: 40px;
  border-radius: 2.85714px;
  transition: 0.3s;
  background: black;
  content: '';
  position: absolute;
  left: 0;
  -webkit-transform-origin: 2.85714px center;
  transform-origin: 2.85714px center;
  width: 100%;
}

.tcon-menu__lines::before {
  top: 10px;
}

.tcon-menu__lines::after {
  top: -10px;
}

.tcon-transform .tcon-menu__lines {
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}

.tcon-menu--xcross {
  position: relative;
  width: auto;
  &:after {
    content: 'Меню';
    position: absolute;
    top: 8px;
    right: -63px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 25px;
    color: #222;
    text-transform: uppercase;
  }
}

.tcon-menu--xcross.tcon-transform .tcon-menu__lines {
  background: transparent;
}

.tcon-menu--xcross.tcon-transform .tcon-menu__lines::before, .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  top: 0;
  width: 40px;
}

.tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tcon-visuallyhidden:active, .tcon-visuallyhidden:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}*/

//**************my variables
@color--white: #ffffff;
@color--black: #262626;
@color--purple: #8d35c0;
@color--yellow: #fecc0f;

@font-family--roboto: 'robotocondensed', sans-serif;
@font-family--roboto-bold: 'robotobold_condensed', sans-serif;
@font-family--ubuntu: 'ubunturegular', sans-serif;
@font-family--ubuntu-bold: 'ubuntubold', sans-serif;
//**************my styles
body {
  font-family: @font-family--roboto;
  font-weight: 400;
  color: @color--black;
}

a {
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1280px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.btn {
  color: @color--black;
  display: inline-block;
  height: 34px;
  border-radius: 17px;
  background-color: #fecc0f;
  text-transform: uppercase;
  font-size: 14.58px;
  .padding-btn(40px);
  font-family: @font-family--ubuntu;
  font-weight: 700;
  line-height: 34px;
  text-decoration: none;
  .transition();
  &:hover {
    background-color: #09b258;
  }
  &--big {
    height: 50px;
    border-radius: 25px;
    font-size: 16.67px;
    line-height: 50px;
    .padding-btn(64px)
  }
  &--transparent {
    height: 50px;
    border-radius: 25px;
    line-height: 44px;
    border: 3px solid #fecc0f;
    background: transparent;
    &:hover {
      background: darken(#efe8e8, 10%);
    }
  }
  &--separator {
    .padding-btn(0);
    min-width: 200px;
    position: relative;
    text-align: center;
    margin-right: 60px;
    &:after {
      content: '';
      display: inline-block;
      height: 100%;
      width: 1px;
      position: absolute;
      right: -35px;
      background: @color--yellow;
      @media screen and (max-width: 590px) {
        content: none;
      }
    }
    @media screen and (max-width: 590px) {
      margin-right: 0;
    }
  }
}

.header {
  width: 100%;
  background: #262626;
  padding-top: 15px;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
}

.logo__link {
  display: inline-block;
  width: 300px;
  height: 88px;
  background: transparent url("../img/logo.png") no-repeat;
  @media screen and (max-width: 590px) {
    width: 160px;
    background-size: 100% auto;
    background-position: 0 50%;
  }
}

.header__rules {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3px;
}

.menu__inner {
  display: flex;
  font-family: @font-family--ubuntu;
  color: #ffffff;
  font-size: 14.58px;
  font-weight: 700;
  text-align: right;
  padding-top: 2px;
  @media screen and (max-width: 1180px) {
    display: none;
    position: absolute;
    z-index: 3;
    top: 100%;
    right: 0;
    width: 300px;
    background: rgba(38, 38, 38, 0.7);
    text-align: center;
    font-size: 18px;
    line-height: 2;
    padding-bottom: 20px;
    &--footer-menu {
      top: auto;
      bottom: calc(~"100% + 46px");
    }
  }
}

.mobile-menu {
  width: 40px;
  height: 34px;
  border: 1px solid @color--purple;
  border-radius: 5px;
  padding: 6px 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
  span {
    display: block;
    height: 2px;
    background: @color--purple;
  }
  @media screen and (min-width: 1180px) {
    display: none;
  }
}

.menu__item {
  margin-right: 15px;
  @media screen and (max-width: 1230px) {
    margin-right: 10px;
  }
  padding-bottom: 5px;
  padding-top: 7px;
  border-bottom: 2px solid transparent;
  .transition();
  &:hover {

    border-bottom-color: @color--purple;
  }
}

.menu__link {
  text-transform: uppercase;
  text-decoration: none;
}

.languages {
  position: relative;
  margin-left: 18px;
}

.flag {
  width: 57px;
  height: 33px;
  padding-right: 24px;
  display: block;
  cursor: pointer;
  &:after {
    content: '';
    display: inline-block;
    border-top: 10px solid #ffffff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: 15px;
    right: 0;
  }
}

.flag-ru {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: transparent url("../img/lang.png") no-repeat 0 0;
}

.flag-en {
  display: none;
  width: 33px;
  height: 33px;
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: translateY(100%);
}

.flag-en-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: transparent url("../img/lang.png") no-repeat 0 -58px;
}

.slider__wrapper {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  font-family: @font-family--ubuntu;
  .btn {
    .padding-btn(38px);
  }
}

.header__slider {
  .slick-prev {
    left: 60px;
    z-index: 10;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.37);
    width: 81px;
    height: 81px;
    @media screen and (max-width: 1500px) {
      left: 5px;
    }
  }
  .slick-prev:before {
    content: "";
    display: inline-block;
    width: 52px;
    height: 36px;
    background-image: url("../img/arrow-left.png");
  }
  .slick-next {
    right: 60px;
    z-index: 10;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.37);
    width: 81px;
    height: 81px;
    @media screen and (max-width: 1500px) {
      right: 5px;
    }
  }
  .slick-next:before {
    content: "";
    display: inline-block;
    width: 52px;
    height: 36px;
    background-image: url("../img/arrow-right.png");
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: 18px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 17;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    border: 0;
    background: #6d6d6d;
    display: block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
  }

  .slick-dots li button:before {
    display: block;
    border-radius: 50%;
    background-color: @color--purple;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -2px;
    left: -2px;
    content: '';
    text-align: center;
    color: #000;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    filter: alpha(opacity=25);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li.slick-active button:before {
    color: #000;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    filter: alpha(opacity=75);
  }

}

.slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4 {
  width: 100%;
  min-height: 700px;
  background-position: center;
  background-size: auto 100%;
  @media screen and (max-width: 1300px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slider-item-1 {
  background-image: url("../img/slider-bg-01.jpg");
  background-repeat: no-repeat;
  padding-top: 112px;
  @media screen and (max-width: 1300px) {
    background-position: right;
  }
  @media screen and (max-width: 660px) {
    padding-top: 150px;
  }
  .btn {
    margin-right: 80px;
    @media screen and (max-width: 660px) {
      margin-right: 0;
    }
  }
}

.slider-item-1__title1 {
  margin-left: 30px;
  padding-left: 61px;
  height: 80px;
  border-radius: 40px 0 0 40px;
  line-height: 80px;
  background: @color--purple;
  font-family: @font-family--ubuntu;
  color: #ffffff;
  font-size: 45.83px;
  font-weight: 700;
  @media screen and (max-width: 1300px) {
    display: inline-block;
    margin-left: 0;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 40px;
  }
  @media screen and (max-width: 660px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
  @media screen and (max-width: 400px) {
    height: auto;
    line-height: 2;
  }

}

.slider-item-1__title-wrapper {
  width: 100%;
  max-width: 710px;
  text-align: right;
  @media screen and (max-width: 1300px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 660px) {
    text-align: center;
  }
}

.slider-item-1__title2 {
  padding-top: 55px;
  font-family: @font-family--ubuntu;
  color: #262626;
  font-size: 45.83px;
  text-align: center;
  @media screen and (max-width: 660px) {
    padding-top: 30px;
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }

}

.slider-item-1__title3 {
  padding-top: 40px;
  margin-bottom: 55px;
  font-family: @font-family--ubuntu;
  color: #262626;
  font-size: 95.83px;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 660px) {
    padding-top: 30px;
    font-size: 60px;
  }
  @media screen and (max-width: 400px) {
    font-size: 40px;
  }

}

.slider-item-2 {
  background-image: url("../img/slider-bg-02.jpg");
  background-repeat: no-repeat;
  padding-top: 80px;
  @media screen and (max-width: 1300px) {
    background-position: left;
  }
  .slider-item__inner {
    text-align: right;
  }
}

.slider-item-2__title1 {
  display: inline-block;
  background-color: #09b258;
  width: 100%;
  max-width: 607px;
  height: 80px;
  color: #ffffff;
  font-size: 43.75px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 1300px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 660px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }

}

.slider-item-2__title2 {
  padding-top: 30px;
  display: inline-block;
  width: 100%;
  max-width: 607px;
  font-weight: 700;
  color: #ffffff;
  font-size: 150px;
  text-align: center;
  @media screen and (max-width: 1300px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 500px) {
    font-size: 100px;
  }
  span {
    font-size: 0.5em;
  }
}

.slider-item-2__title3 {
  padding-top: 65px;
  display: inline-block;
  width: 100%;
  max-width: 607px;
  color: #ffffff;
  font-size: 37.5px;
  font-weight: 400;
  text-align: left;
  @media screen and (max-width: 1300px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media screen and (max-width: 660px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }

  span {
    color: @color--purple;
  }
}

.slider-item-2__link-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 607px;
  text-align: left;
  margin-top: 65px;
  @media screen and (max-width: 1300px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
  }
  @media screen and (max-width: 660px) {
    text-align: center;
  }

}

.slider-item-3 {
  background-image: url("../img/slider-bg-03.jpg");
  background-repeat: no-repeat;
  padding-top: 110px;
  @media screen and (max-width: 680px) {
    padding-top: 103px;
  }
}

.slider-item-3__title1 {
  display: flex;
  justify-content: center;
  color: #262626;
  font-size: 37.5px;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 680px) {
    font-size: 30px;
    height: 110px;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
  @media screen and (max-width: 405px) {
    font-size: 18px;
  }
  p {
    max-width: 300px;
    &:first-child {
      margin-right: 140px;
      @media screen and (max-width: 500px) {
        margin-right: 105px;
      }

    }
  }
  .purple-bold {
    color: @color--purple;
    font-weight: 700;
  }
}

.purple {
  color: @color--purple;
}

.slider-item-3__title2 {
  background-color: @color--purple;
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 108px;
  height: 80px;
  color: #ffffff;
  font-size: 43.75px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 850px) {
    font-size: 32px;
  }
  @media screen and (max-width: 660px) {
    font-size: 26px;
    line-height: normal;
    padding: 10px;
    height: auto;
  }

}

.slider-item-3__link-wrapper {
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  padding-top: 145px;
}

.slider-item-4 {
  background-image: url("../img/slider-bg-04.jpg");
  background-repeat: no-repeat;
  padding-top: 93px;
  .slider-item__inner {
    text-align: right;
    @media screen and (max-width: 1300px) {
      text-align: left;
      padding-left: 30px;
    }
    @media screen and (max-width: 660px) {
      padding-left: 0;
    }

  }
}

.slider-item-4__content-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 630px;
  text-align: left;
}

.slider-item-4__title1 {
  font-size: 45.83px;
  font-weight: 700;
  text-align: left;
  @media screen and (max-width: 660px) {
    font-size: 32px;
    text-align: center;
  }

}

.slider-item-4__title2 {
  color: #8d35c0;
  font-size: 50px;
  font-weight: 700;
  text-align: left;
  padding-top: 36px;
  @media screen and (max-width: 660px) {
    font-size: 42px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 36px;
  }
}

.slider-item-4__title3 {
  color: #262626;
  font-size: 45.83px;
  font-weight: 400;
  text-align: left;
  padding-top: 40px;
  @media screen and (max-width: 660px) {
    font-size: 40px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 26px;
  }
  ul {
    padding-left: 25px;
    @media screen and (max-width: 660px) {
      display: inline-block;
      text-align: left;
      width: 400px;
      padding-left: 10px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }

  }
  li {
    text-indent: -25px;
    @media screen and (max-width: 500px) {
      text-indent: -13px;
    }
  }
}

.slider-item-4__link-wrapper {
  padding-top: 56px;
  @media screen and (max-width: 660px) {
    text-align: center;
  }

}

.slider-item__inner {
  width: 50%;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  &--center {
    width: 100%;
  }
  &--right {
    transform: translateX(100%);
    @media screen and (max-width: 1300px) {
      transform: none;
      text-align: center;
    }
  }
}

.main__info {
  background: transparent url("../img/info-bg.jpg");
  padding-top: 120px;
  padding-bottom: 105px;
}

.info__inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
}

.info__item {
  display: flex;
  width: 48%;
  @media screen and (max-width: 1200px) {
    width: 570px;
  }
  @media screen and (max-width: 590px) {
    width: 100%;
  }

  padding: 22px;
  position: relative;
  background: rgb(222, 127, 247);
  background: -moz-linear-gradient(-45deg, rgba(222, 127, 247, 1) 0%, rgba(128, 83, 138, 1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(222, 127, 247, 1) 0%, rgba(128, 83, 138, 1) 100%);
  background: linear-gradient(135deg, rgba(222, 127, 247, 1) 0%, rgba(128, 83, 138, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#de7ff7', endColorstr='#80538a', GradientType=1);
  &:before {
    content: '';
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background-color: @color--purple;
    background-image: url("../img/icons.png");
    background-repeat: no-repeat;
    background-position: 19px 22px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--type-2 {
    @media screen and (max-width: 1200px) {
      margin-top: 100px;
    }
    &:before {
      background-position: 19px -160px;
    }
  }
}

.info__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 52px 0 0;
  background: #ffffff;
  text-align: center;
}

.content__title-1 {
  font-family: @font-family--ubuntu;
  font-size: 45.83px;
  font-weight: 700;
  @media screen and (max-width: 590px) {
    font-size: 32px;
  }
  @media screen and (max-width: 500px) {
    font-size: 26px;
  }

  &:after {
    content: '';
    display: block;
    margin: 13px auto 21px;
    width: 129px;
    height: 5px;
    background: @color--purple;
  }
}

.content__title-2 {
  font-size: 24.58px;
  font-weight: 700;
  margin-bottom: 35px;
}

.content__list {
  text-align: left;
  font-size: 22.08px;
  padding-left: 56px;
  padding-right: 18px;
  padding-bottom: 10px;
  @media screen and (max-width: 500px) {
    padding-right: 5px;
    padding-left: 43px;
  }

}

.content-list__item {
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 27px;
  p {
    text-indent: -27px;
    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: @color--purple;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}

.content__control {
  padding-top: 49px;
  padding-bottom: 44px;
  border-top: 4px solid @color--black;
  background: #efe8e8;
  @media screen and (max-width: 590px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 180px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

}

.page-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.page-title__text {
  display: inline-block;
  font-family: @font-family--ubuntu;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 500px) {
    font-size: 30px;

  }
  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 130px;
    height: 5px;
    background: @color--purple;
    vertical-align: middle;
    margin: 0 37px;
    @media screen and (max-width: 1100px) {
      display: block;
      margin: 20px auto;
    }
  }
  &--white {
    color: @color--white;
    &:after,
    &:before {
      background: @color--white;
    }
  }
}

.page-title-1 {
  margin-top: 12px;
  text-align: center;
  font-size: 37.5px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    font-weight: 700;
  }
}

.main__arrangement {
  padding-top: 90px;
  @media screen and (max-width: 1000px) {
    padding-top: 10px;
  }

}

.arrangement-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 25px;
  text-align: center;
  padding-top: 41px;
  padding-bottom: 80px;
  @media screen and (max-width: 1300px) {
    width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 660px) {
    width: 300px;
  }
}

.arrangement-content__item-1,
.arrangement-content__item-2,
.arrangement-content__item-3,
.arrangement-content__item-4 {
  width: 319px;
  padding: 110px 30px 0;
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  span {
    font-weight: 700;
  }
}

.arrangement-content__item-1 {
  border-left: 5px solid @color--purple;
  background-position: 114px -418px;
  @media screen and (max-width: 1300px) {
    padding-bottom: 25px;
    border-bottom: 5px solid @color--purple;
  }
  @media screen and (max-width: 660px) {
    border-left: none;
    background-position-x: 110px;
  }
}

.arrangement-content__item-2 {
  border-left: 5px solid @color--purple;
  border-right: 5px solid @color--purple;
  background-position: 114px -889px;
  position: relative;
  @media screen and (max-width: 1300px) {
    padding-bottom: 25px;
    border-bottom: 5px solid @color--purple;
  }
  @media screen and (max-width: 660px) {
    border-left: none;
    border-right: none;
    background-position-x: 110px;
  }
  &:after {
    position: absolute;
    content: '\f105';
    display: inline-block;
    width: 30px;
    height: 70px;
    background: @color--white;
    font-family: FontAwesome;
    font-size: 55px;
    line-height: 70px;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    @media screen and (max-width: 1300px) {
      top: 100%;
      right: 50%;
      transform-origin: 100% 50%;
      transform: rotate(90deg) translate(-50%, 0);
    }
  }
  &:before {
    position: absolute;
    content: '\f105';
    display: inline-block;
    width: 30px;
    height: 70px;
    background: @color--white;
    font-family: FontAwesome;
    font-size: 55px;
    line-height: 70px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 660px) {
      top: 0;
      left: 50%;
      transform-origin: 100% 50%;
      transform: rotate(90deg) translate(-65%, 40%);
    }
  }
}

.arrangement-content__item-3 {
  border-right: 5px solid @color--purple;
  background-position: 115px -1405px;
  position: relative;
  @media screen and (max-width: 1300px) {
    padding-bottom: 25px;
    order: 2;
    border-left: 5px solid @color--purple;
  }
  @media screen and (max-width: 660px) {
    order: inherit;
    border-left: none;
    border-right: none;
    border-bottom: 5px solid @color--purple;
    background-position-x: 105px;
  }
  &:after {
    position: absolute;
    content: '\f105';
    display: inline-block;
    width: 30px;
    height: 70px;
    background: @color--white;
    font-family: FontAwesome;
    font-size: 55px;
    line-height: 70px;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    @media screen and (max-width: 1300px) {
      top: 50%;
      right: 100%;
      transform-origin: 100% 50%;
      transform: rotate(180deg) translate(50%, 50%);
    }
    @media screen and (max-width: 660px) {
      top: 100%;
      left: 50%;
      transform-origin: 100% 50%;
      transform: rotate(90deg) translate(-50%, 40%);
    }
  }
}

.arrangement-content__item-4 {
  border-right: 5px solid @color--purple;
  background-position: 121px -1900px;
  @media screen and (max-width: 1300px) {
    order: 1;
    border-left: 5px solid @color--purple;
    border-right: none;
    padding-bottom: 25px;
  }
  @media screen and (max-width: 660px) {
    order: inherit;
    border-left: none;
    background-position-x: 115px;
  }
}

.main__registration {
  background: @color--purple;
}

.registration__inner {
  width: 100%;
  max-width: 1920px;
  min-height: 710px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  background: @color--purple url("../img/reg-bg.jpg") no-repeat center 0;
  @media screen and (max-width: 700px) {
    background-size: cover;
    padding-bottom: 20px;
  }
  .reg-rules-link {
    &:hover {
      text-decoration: none;
      color: @color--black;
    }
  }
}

.checked {
  .transition();
  label {
    display: inline-block;
    cursor: pointer;
    border-width: 4px;
    border-color: #f5f7f6;
    border-style: solid;
    width: 250px;
    height: 61px;
  }
  .checked__background {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: @color--purple;
  }
  .checked__check-box {
    display: inline-block;
    border-width: 1.25px;
    border-color: #8d35c0;
    border-style: solid;
    background-color: #e9e9e9;
    width: 45px;
    height: 45px;
    margin: 4px 0 4px 4px;
    vertical-align: middle;
    position: relative;

    //&:before {
    //  content: '';
    //  display: inline-block;
    //  width: 24px;
    //  height: 1px;
    //  background: @color--purple;
    //  transform-origin: 0 0;
    //  transform: rotate(-122deg);
    //  position: absolute;
    //  bottom: 8px;
    //  left: 50%;
    //}
    //&:after {
    //  content: '';
    //  display: inline-block;
    //  width: 30px;
    //  height: 1px;
    //  background: @color--purple;
    //  transform-origin: 0 0;
    //  transform: rotate(-57deg);
    //  position: absolute;
    //  bottom: 8px;
    //  left: 50%;
    //}
  }
  .checked__txt {
    display: inline-block;
    width: calc(~"100% - 60px");
    font-family: @font-family--ubuntu;
    color: #e9e9e9;
    font-size: 16.67px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + label {
    .checked__background {
      background-color: #e9e9e9;
    }
    .checked__check-box {
      &:before {
        content: '';
        display: inline-block;
        width: 22px;
        height: 1px;
        background: @color--purple;
        transform-origin: 0 0;
        transform: rotate(-122deg);
        position: absolute;
        bottom: 8px;
        left: 50%;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 30px;
        height: 1px;
        background: @color--purple;
        transform-origin: 0 0;
        transform: rotate(-57deg);
        position: absolute;
        bottom: 8px;
        left: 50%;
      }
    }
    .checked__txt {
      color: @color--purple;
    }
  }
  &--separator {
    position: relative;
    margin-right: 72px;
    @media screen and (max-width: 590px) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 100%;
      background: whitesmoke;
      position: absolute;
      top: 0;
      right: -39px;
      @media screen and (max-width: 590px) {
        content: none;
      }

    }
  }
}

.reg-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.checked-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 42px;
  padding-bottom: 57px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;
  }

}

.reg-form__item, .reg-form__password {
  width: 100%;
  height: 50px;
  background-color: #e9e9e9;
  padding: 4px;
  margin-bottom: 29px;
  input, textarea {
    display: inline-block;
    padding-left: 15px;
    width: calc(~"100% - 50px");
    font-family: @font-family--ubuntu;
    color: #757575;
    font-size: 25px;
    text-align: left;
    border: none;
    background: transparent;
  }
  textarea {
    vertical-align: top;
    min-height: 130px;
    resize: none;
  }
  .fa {
    display: inline-block;
    width: 42px;
    height: 42px;
    color: #e9e9e9;
    background-color: @color--purple;
    text-align: center;
    font-size: 20px;
    &:before {
      line-height: 42px;
    }
  }
  &--small {
    width: 48%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
  &--text-field {
    height: auto;
  }
}

.password-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 590px) {
    flex-direction: column;
  }

}

.reg-form__password {
  width: 47%;
  @media screen and (max-width: 590px) {
    width: 100%;
  }
}

.checked-accept {
  @media screen and (max-width: 590px) {
    margin-bottom: 15px;
  }

  label {
    cursor: pointer;
    span:first-of-type {
      display: inline-block;
      margin-right: 15px;
      background-color: #e9e9e9;
      width: 21px;
      height: 21px;
      vertical-align: middle;
      &.fa {
        color: transparent;
        text-align: center;
        line-height: 21px;
      }
    }
    span:last-of-type {
      font-family: @font-family--ubuntu;
      color: #ffffff;
      font-size: 22.92px;
      font-weight: 400;
      text-align: right;
      vertical-align: middle;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:checked + label span.fa-check {
    padding-left: 1px;
    color: #000000;
  }
}

.form__submit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
  }

}

.btn[disabled] {
  cursor: not-allowed;
  color: @color--black;
}

.main__advantages {
  background: transparent url("../img/info-bg.jpg");
  padding-top: 60px;
  padding-bottom: 65px;
  border-bottom: 5px solid #e9e9e9;
}

.advantages-content-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
}

.advantages-content__item-1 {
  width: 49%;
  @media screen and (max-width: 1300px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 590px) {
    background-image: none;
    padding-left: 10px;
    padding-right: 10px;
  }

  padding-left: 131px;
  padding-right: 25px;
  background-image: url("../img/icons-2.png");
  background-repeat: no-repeat;
  background-position: 4px 0;
}

.advantages-content__item-2 {
  width: 48.5%;
  @media screen and (max-width: 1100px) {
    width: 100%;
    max-width: 570px;
    margin-top: 50px;
  }
  @media screen and (max-width: 590px) {
    background-image: none;
    padding-left: 10px;
    padding-right: 10px;
  }

  padding-left: 131px;
  padding-right: 25px;
  background-image: url("../img/icons-2.png");
  background-repeat: no-repeat;
  background-position: 0 -566px;
}

.advantages-content-tittle {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 30px;
}

.advantages-content-text {
  font-size: 25px;
  text-align: left;
}

.main__questions {
  padding-top: 73px;
  padding-bottom: 73px;
  .form__submit {
    padding-top: 0;
  }
}

.question-form-wrapper {
  padding-top: 48px;
  padding-right: 425px;
  min-height: 350px;
  background: transparent url("../img/icons-2.png") no-repeat;
  background-position: 100% -1100px;
  @media screen and (max-width: 1200px) {
    padding-right: 300px;
    background-size: 250px auto;
    background-position: 100% -770px;
  }
  @media screen and (max-width: 1200px) {
    padding-right: 110px;
    background-size: 90px auto;
    background-position: 100% -250px;
  }
  @media screen and (max-width: 590px) {
    padding-right: 0;
    background-image: none;
  }

}

.data-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 1000px) {
    display: block;
  }
}

.footer {
  background: @color--black;
  padding-top: 46px;
  .menu__item {
    &:not(:last-child) {
      margin-right: 11px;
    }
  }
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 16px;
  @media screen and (max-width: 970px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }
}

.footer__social {
  width: 100%;
  max-width: calc(~"100% - 290px");
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  color: #ffffff;
  font-size: 25px;
  text-align: left;
  padding-bottom: 35px;
  @media screen and (max-width: 970px) {
    max-width: 100%;
    padding-left: 10%;
  }
  & > div {
    width: 330px;
    padding-right: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 970px) {
      width: auto;
      min-width: 250px;
      padding-right: 0;
    }
  }
  .fa {
    width: 27px;
    text-align: center;
    color: @color--purple;
    margin-right: 22px;
  }
}

.social-link {
  text-decoration: none;
  &:hover {
    .fa {
      color: @color--white;
      .transition();
    }
  }
}

.wm {
  display: inline-block;
  width: 88px;
  height: 31px;
  background-image: url("../img/88x31_wm_blue.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  //border-radius: 5px;
  &--2 {
    background-image: url("../img/v_blue_on_white_ru.png");
    //background-position: 0 0;
    margin-left: 20px;
    border-radius: 0;
  }
}

.footer__copyright {
  padding-top: 28px;
  padding-bottom: 38px;
  border-top: 2px solid #8a8989;
  color: #9b9b9b;
  font-size: 25px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

}

.copyright-item {
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  &:hover {
    .transition();
    border-bottom-color: @color--purple;
  }
  a {
    text-decoration: none;
  }
  &--separator {
    position: relative;
    margin-right: 59px;
    @media screen and (max-width: 640px) {
      margin-right: 0;
      margin-bottom: 5px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 30px;
      background: #8a8989;
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translateY(-50%);
      @media screen and (max-width: 640px) {
        position: relative;
        top: auto;
        right: auto;
        transform: none;
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 15px;
      }
    }
  }
}

.pop-up {
  //display: none;
  //opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: -200%;
  left: 0;
  z-index: 98;
  background: transparent;
  padding: 10px;
  transition: .5s;
  transition-property: background, top;
  &.active {
    display: flex;
  }
  .checked label {
    border-color: @color--purple;
  }
  .checked .checked__background {
    background: transparent;
  }
  .checked .checked__txt {
    color: @color--black;
  }
  .reg-form__item, .reg-form__password {
    background-color: #ffffff;
  }
  .checked-accept label span:first-of-type {
    border: 1px solid @color--purple;
  }
  .checked-accept label span:last-of-type {
    color: @color--black;
  }
  .reg-rules-link {
    &:hover {
      text-decoration: none;
      color: @color--purple;
    }
  }
  .checked-wrapper {
    @media screen and (max-width: 690px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .checked--separator {
    @media screen and (max-width: 690px) {
      margin-right: 0;
      margin-bottom: 20px;
      &:after {
        content: none;
      }
    }
  }
  .form__submit {
    @media screen and (max-width: 690px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .checked-accept {
    @media screen and (max-width: 690px) {
      margin-bottom: 15px;
    }
  }
  .fa {
    @media screen and (max-width: 500px) {
      width: 0;
      font-size: 18px;
    }
  }
  .reg-form__item input, .reg-form__item textarea, .reg-form__password input, .reg-form__password textarea {
    @media screen and (max-width: 500px) {
      width: 95%;
    }
  }
}

.pop-up__content-wrapper {
  background: linear-gradient(135deg, #de7ff7, #80538a);
  padding: 40px;
  width: 100%;
  height: auto;
  max-width: 730px;
  margin: auto;
  @media screen and (max-width: 500px) {
    padding: 15px;
  }
}

.pop-up__content {
  background: #e9e9e9;
  border: 2px solid #8a8989;
  padding-top: 65px;
  padding-bottom: 85px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  @media screen and (max-width: 500px) {
    padding-bottom: 30px;
  }
}

.pop-up__title {
  font-family: @font-family--ubuntu;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 40px;
  }
  @media screen and (max-width: 540px) {
    font-size: 24px;
  }
}

.pop-up__text {
  display: inline-block;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.pop-up__close {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: @color--purple;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  .transition();
  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #e9e9e9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover {
    background-color: lighten(@color--purple, 10%);
  }
}

.reg-form__link {
  text-align: center;
  font-family: @font-family--ubuntu;
  color: @color--purple;
  font-size: 25px;
  line-height: 83px;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:first-child {
      margin-right: 40px;
    }
  }
}

.pop-up--log-in {
  //display: flex;
  .form__submit {
    justify-content: center;
  }
  .reg-form {
    margin-top: 40px;
  }
  .pop-up__content {
    padding-bottom: 65px;
  }
}